<template>
    <div class="ad">
        <a-card
            :title="`${item.title} （最多${item.max}张）`"
            v-for="(item,index) in list"
            :key="index"
            class="ad-card"
        >
            <a-upload
                accept="image/*;video/*"
                :before-upload="file => add(file, index)"
                :show-upload-list="false"
                slot="extra"
                :disabled="item.list.length >= item.max"
            >
                <a-button type="primary" :loading="uploading === index" @click="disabledUpload(item)">添加</a-button>
            </a-upload>

            <ul class="ad-images-group">
                <li class="ad-images-item" v-for="el in item.list" :key="el.code">
                    <div class="ad-image-box">
                        <img v-if="el.ba_type === '1'" :src="el.title_img" alt class="ad-image" />
                        <video :src="el.title_img" class="ad-image" v-else></video>
                        <ul class="ad-image-tools">
                            <li @click="removeBanner(el)">
                                <a-icon type="delete" />
                                <p>删除</p>
                            </li>
                            <li @click="play(el)" v-if="el.ba_type === '2'">
                                <a-icon type="play-circle" />
                                <p>播放</p>
                            </li>
                            <li>
                                <a-upload
                                    accept="image/*;video/*"
                                    :before-upload="file => replaceImage(file, el,item.index)"
                                    :show-upload-list="false"
                                >
                                    <div style="color: #fff;">
                                        <a-icon type="upload" />
                                        <p>更换图片</p>
                                    </div>
                                </a-upload>
                            </li>
                        </ul>
                    </div>
                    <p class="title">{{ el.width }}*{{ el.height }}</p>
                    <a-input v-model="el.href" @change="activeLinkCode = el.code"></a-input>
                    <p style="text-align: right">
                        <a-button
                            type="link"
                            @click="updateLink(el)"
                            :disabled="activeLinkCode !== el.code"
                        >保存链接</a-button>
                    </p>
                </li>
            </ul>
        </a-card>

        <a-modal title="提示" :visible="isShowModal" @ok="handleOk" @cancel="isShowModal = false">
            <p>还有链接没有保存，是否离开当前页面？</p>
        </a-modal>

        <div class="video-box" v-if="isShowVideo" @click="isShowVideo = false">
            <video :src="vedio" class="vedio" controls autoplay></video>
        </div>
    </div>
</template>

<script>
import { Card } from 'ant-design-vue'
import { getBannerList, changeBannerStatus, addBanner, updateBanner } from '@/api/admin'
import upload from '@/plugins/upload'
import { getUserInfo } from '@/utils/auth'

export default {
  components: {
    [Card.name]: Card,
    [Card.Grid.name]: Card.Grid
  },
  data () {
    return {
      list: [],
      uploading: -1,
      activeLinkCode: '',
      isShowModal: false,
      nextRoute: null,
      isShowVideo: false,
      vedio: ''
    }
  },
  created () {
    this.getList()
  },
  beforeRouteLeave (to, from, next) {
    if (this.activeLinkCode) {
      this.isShowModal = true
      this.nextRoute = to
    } else {
      next()
    }
  },
  methods: {
    getList () {
      getBannerList({
        page: 1,
        per_page: 20
      }).then(list => {
        this.list = list
      }).catch(err => {
        console.error(err)
      })
    },
    removeBanner (item) {
      changeBannerStatus({
        code: item.code,
        status: '2'
      }).then(res => {
        this.$message.success('删除成功')
        this.getList()
      }).catch(err => {
        this.$message.error(err.msg)
      })
    },
    disabledUpload (item) {
      if (item.list.length >= item.max) {
        return this.$message.warning(`轮播图已到达上限${item.max}张`)
      }
    },
    add (file, index) {
      const item = this.list[index]
      this.uploading = index
      const isVideo = file.type.startsWith('video')
      upload(file).then(async res => {
        const name = getUserInfo()
        await addBanner({
          title: item.title,
          author: name,
          href: isVideo ? res : '',
          title_img: res,
          status: '0', // # "0":展示 "1":不展示
          sort: 100, // # 排序
          width: item.width,
          height: item.height,
          ba_type: isVideo ? '2' : '1', // # "1":链接 "2":视频
          banner_type: item.index
        })
        this.uploading = -1
        this.$message.success('添加成功')
        this.getList()
      }).catch(err => {
        console.error(err)
        this.uploading = -1
        this.$message.error(err.msg)
      })
      return false
    },
    replaceImage (file, item, type) {
      const isVideo = file.type.startsWith('video')
      upload(file).then(async res => {
        console.log(item, 'item')
        await updateBanner({
          code: item.code,
          title: item.title,
          author: item.author,
          href: isVideo ? res : item.href,
          title_img: res,
          sort: item.sort,
          height: item.height,
          width: item.width,
          ba_type: isVideo ? '2' : '1', // # "1":链接 "2":视频
          banner_type: type
        })
        this.$message.success('更新成功')
        this.getList()
      }).catch(err => {
        console.error(err)
        this.$message.error(err.msg)
      })
      return false
    },
    updateLink (item) {
      updateBanner({
        code: item.code,
        title: item.title,
        author: item.author,
        href: item.href,
        title_img: item.ba_type === '2' ? item.href : item.title_img,
        sort: item.sort,
        height: item.height,
        width: item.width
      }).then(() => {
        this.activeLinkCode = ''
        this.getList()
        this.$message.success('保存成功')
      }).catch(err => {
        this.$message.error(err.msg)
      })
    },
    handleOk () {
      this.activeLinkCode = ''
      if (this.nextRoute) {
        this.$router.push(this.nextRoute)
      }
    },
    play (item) {
      this.isShowVideo = true
      this.vedio = item.title_img
      this.$nextTick(() => {

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ad {
    padding: 20px;

    .ad-card {
        margin-bottom: 20px;

        .ad-images-group {
            display: flex;
            .ad-images-item {
                width: 20%;
                padding: 15px;
                text-align: center;

                .title {
                    padding: 10px;
                }
            }

            .ad-image-box {
                position: relative;
                min-height: 100px;
                display: flex;
                align-items: center;

                &:hover {
                    .ad-image-tools {
                        transform: scale(1);
                    }
                }

                .ad-image-tools {
                    transform: scale(0);
                    transition: transform 0.3s;
                    position: absolute;
                    background: rgba($color: #000000, $alpha: 0.8);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    li {
                        flex: 1;
                        cursor: pointer;
                        font-size: 16px;
                        color: #fff;

                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .ad-image {
            width: 100%;
        }
    }

    .video-box {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color: #000000, $alpha: 0.8);
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;

        .vedio {
            max-width: 80%;
            width: 1980px;
        }
    }
}
</style>
